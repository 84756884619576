export default {
  domains: {
    'tipple.com.au': {
      siteId: 1,
      defaultStoreId: 34,
      segmentKey: 'SnAbdJoOW1pfbexM70tTuYgT11jKHfsD',
      googleApiKey: 'AIzaSyAGtakAzPx40Unrw5TWW7FybRiwlk1l2BU'
    },
    'marketplace.tipple.com.au': {
      siteId: 3,
      defaultStoreId: 72,
      segmentKey: 'UYsmHLjTMzzXBxkfh985U3fn0SkzoCjw',
      googleApiKey: 'AIzaSyC1o14xwzF5tVKdtv3mY7qrsEuVcXIPe5c'
    },
    '7eleven.tipple.com.au': {
      siteId: 7,
      defaultStoreId: 176,
      unavailableComponent: '7ElevenUnavailable',
      segmentKey: '0VhG3OwP1kSC01O0iBsrCkErrEREGhsL',
      googleApiKey: 'AIzaSyCv6As-AAoUFpqdp3HZR3kgq4kHqVB-InM',
      showDefaultPrice: true
    },
    'railway.tipple.com.au': {
      siteId: 9,
      defaultStoreId: 152,
      segmentKey: 'VLtxKw7cGOMPLCMeABxIKRbq1klYdce2',
      showDefaultPrice: true
    },
    'youfoodz.tipple.com.au': {
      siteId: 10,
      defaultStoreId: 193,
      unavailableComponent: '7ElevenUnavailable',
      segmentKey: '8EA0ud3l0vqFoiHU8i7FNd4WOJdGjfrh'
    },
    'tippletest.xyz': {
      siteId: 13,
      defaultStoreId: 318,
      unavailableComponent: 'YouFoodzUnavailable'
    },
    'chemist.tipple.com.au': {
      siteId: 20,
      defaultStoreId: 328
    },
    'cocktailhq.tipple.com.au': {
      siteId: 22,
      defaultStoreId: 34
    },
    'tpliquor.tipple.com.au': {
      siteId: 23,
      defaultStoreId: 34
    },
    'thirstycamelhampton.tipple.com.au': {
      siteId: 25,
      defaultStoreId: 34
    },
    'igaeastbrighton.tipple.com.au': {
      siteId: 26,
      defaultStoreId: 34
    },
    'cellarbrations.tipple.com.au': {
      siteId: 27,
      defaultStoreId: 34
    },
    'bottleo.tipple.com.au': {
      siteId: 28,
      defaultStoreId: 34
    },
    'liquorbarons.tipple.com.au': {
      siteId: 29,
      defaultStoreId: 34
    },
    'bottler.tipple.com.au': {
      siteId: 30,
      defaultStoreId: 34
    },
    'fleetstreet.tipple.com.au': {
      siteId: 31,
      defaultStoreId: 34
    },
    'thebottleostkilda.tipple.com.au': {
      siteId: 32,
      defaultStoreId: 34
    },
    'australianwineclearance.tipple.com.au': {
      siteId: 33,
      defaultStoreId: 34
    },
    'sessionsarden.tipple.com.au': {
      siteId: 34,
      defaultStoreId: 34
    },
    'sessionskew.tipple.com.au': {
      siteId: 35,
      defaultStoreId: 34
    },
    'liquidhq.tipple.com.au': {
      siteId: 36,
      defaultStoreId: 34
    },
    'duncansmitcham.tipple.com.au': {
      siteId: 37,
      defaultStoreId: 34
    },
    'bottleoofficer.tipple.com.au': {
      siteId: 38,
      defaultStoreId: 34
    }
  }
};
  
  